import { useEffect, useMemo, useState } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { useForm, useWatch } from "react-hook-form";
import moment from "moment";

import {
  CHECK_AVL_DATE,
  GET_DELIVERY_FEE,
  GET_CONVENIENCE_FEE,
  GET_ONE_USER_FOR_CUSTOMER,
  GET_SALES_TAX,
  LIST_CARDS,
} from "../../Graphql/queries";
import { userDeatils, toggleSnackbar } from "../../ReactiveVariables/index";
import { getUserId, getUserName, getUserType, getUserTaxExempt } from "@utils/user-format.utils";
import {
  calcDistanceNew,
  checkBrowser,
  convertTimeFormat,
  foodAndBeverageTotal,
} from "../../Modules/Common/commonUtils";
import { CREATE_NEW_ORDER } from "./order.mutations";
import { formatTableWare, getPercentValue } from "@utils/order-format.utils";
import {
  CATERER_DRIVER_TIP_TYPE,
  ORDER_DELIVERY_MODE,
  ORDER_TIP_TYPE,
} from "@constants/order.constants";
import { useOrderCalculation } from "@utils/hooks/use-order-calculation";
import { RootState, useAppDispatch, useAppSelector } from "@services/redux";
import {
  IOrderPayload,
  clearDraftOrder,
  clearEditOrder,
  updateDraftOrder,
  updateEditOrder,
} from "@services/redux/order";
import { ROUTES } from "@constants/routes.constants";
import { ISearchLocationPayload } from "@components/atoms/location-search";
import { ICatererDetailsResponse } from "typings/caterer.api";

interface IReviewOrderForm {
  contactName: string;
  date: Date | string;
  location: string;
  street: string;
  contactPhone: string;
  orderType: ORDER_DELIVERY_MODE;
  suit: string;
  instruction: string;
  zip: string;
  coordinates: [any, any];
  cardId: string;
  city: string;
  headCount: number;
  isSaveAddress: boolean;
  discount: number;
  addressId: string | null;
  deliveryFee: number;
  percentageTip: number | null;
  customTip: number | null;
  convenienceFee: number;
}

export function useReviewOrderState() {
  const user = useReactiveVar(userDeatils);

  const { id } = useParams();
  const navigate = useNavigate();

  const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const customerId = getUserId(user);
  const userType = getUserType(user);
  const customerName = getUserName(user);
  const taxExempt = getUserTaxExempt(user);

  const [tableWare, setTableWare] = useState({ value: 0, label: "" });
  const [driverTipAmount, setDriverTipAmount] = useState(0);
  const [cartCost, setCartCost] = useState(0);
  const [caterCash, setCaterCash] = useState(0);

  const [isMandatoryTip, setIsMandatoryTip] = useState(false);
  const [driverTipType, setDriverTipType] = useState(ORDER_TIP_TYPE.custom);
  const [salesTaxPercent, setSalesTaxPercent] = useState(0);

  const dispatch = useAppDispatch();

  const { control, handleSubmit, setValue, trigger, setError } = useForm<IReviewOrderForm>({
    mode: "all",
    defaultValues: {
      contactName: "",
      date: "",
      location: "",
      street: "",
      contactPhone: "",
      orderType: ORDER_DELIVERY_MODE.delivery,
      suit: "",
      instruction: "",
      zip: "" as any,
      coordinates: [] as any,
      cardId: "",
      city: "",
      headCount: 1,
      isSaveAddress: false,
      discount: 0,
      addressId: null,
      deliveryFee: 0,
      percentageTip: null,
      customTip: null,
    },
  });

  const [
    coordsWatcher,
    orderTypeWatcher,
    isSaveAddressWatcher,
    suitWatcher,
    contactNameWatcher,
    instructionWatcher,
    deliveryFeeWatcher,
    zipWatcher,
    cardWatcher,
    percentageTipWatcher,
    discountWatcher,
    headCountWatcher,
    dateWatcher,
    streetWatcher,
    convenienceFeeWatcher,
  ] = useWatch({
    control,
    name: [
      "coordinates",
      "orderType",
      "isSaveAddress",
      "suit",
      "contactName",
      "instruction",
      "deliveryFee",
      "zip",
      "cardId",
      "percentageTip",
      "discount",
      "headCount",
      "date",
      "street",
      "convenienceFee",
    ],
  });

  const isEdit = useMemo(() => {
    return id !== "new";
  }, [id]);

  const userData = useMemo(() => {
    return user && user.data
      ? user.data.currentUserDetails
        ? user.data.currentUserDetails
        : user.data.createUserIdentity
        ? user.data.createUserIdentity
        : user.data.login
        ? user.data.login
        : null
      : null;
  }, [user]);

  const order = useAppSelector((state: RootState) =>
    isEdit ? state.order.edit : state.order.draft,
  );

  const orderId = useMemo(() => {
    return isEdit ? id : order.orderId || null;
  }, [id]);

  const updateAction = useMemo(() => {
    return isEdit ? updateEditOrder : updateDraftOrder;
  }, [isEdit]);

  const { taxCost, totalCost } = useOrderCalculation({
    cartCost,
    tableware: tableWare.value,
    salesTaxPercent,
    deliveryFee: deliveryFeeWatcher,
    driverTip: driverTipAmount,
    discount: discountWatcher,
    convenienceFee: convenienceFeeWatcher,
  });

  const [salesTaxRequest] = useLazyQuery(GET_SALES_TAX, {
    onCompleted: (res) => {
      setSalesTaxPercent(res.getSalesTax.salesTax);
    },
  });

  const [createNewOrderRequest, { loading: isSubmitLoading }] = useMutation(CREATE_NEW_ORDER, {});

  const { data: catererData } = useQuery<ICatererDetailsResponse>(GET_ONE_USER_FOR_CUSTOMER, {
    fetchPolicy: "cache-and-network",
    variables: { getCatererDetailsId: order.catererId },
    skip: !order.catererId,
    onCompleted(res) {
      salesTaxRequest({
        variables: {
          zip: res.getCatererDetails.catererZipCode,
          city: res.getCatererDetails.catererCity,
          catererStateShort: res.getCatererDetails.catererStateShort,
          customerId: customerId,
        },
      });
    },
  });

  const { data: cardList } = useQuery(LIST_CARDS, {
    variables: {
      customerId,
    },
  });

  useEffect(() => {
    setValue("date", new Date(order.deliveryDate));
    setValue("street", order.street); // TODO: update location search to avoid warnings
    setValue("orderType", order.deliveryMode);
    setValue("suit", order.suit);
    setValue("instruction", order.instruction);
    setValue("zip", order.zip);
    setValue("city", order.city);
    setValue("cardId", order.cardId);
    setValue("addressId", order.addressId || null);
    setValue("deliveryFee", order.deliveryFee);
    setValue("location", order.location);
    setValue("coordinates", [+order.lng, +order.lat]);
    setValue("headCount", order.headCount);

    const isUser = !!userData;

    if (!order.contactNo && isUser) {
      onUpdateOrder({
        contactNo: userData.phoneNumber,
      });
    }

    if (!order.contactPerson && isUser) {
      onUpdateOrder({
        contactPerson: userData.fullName ? userData.fullName : userData.name ? userData.name : "",
      });
    }

    setValue(
      "contactPhone",
      order.contactNo ? order.contactNo : isUser ? userData.phoneNumber : "",
    );
    setValue(
      "contactName",
      order.contactPerson
        ? order.contactPerson
        : isUser
        ? userData.fullName
          ? userData.fullName
          : userData.name
          ? userData.name
          : ""
        : "",
    );
  }, []);

  useEffect(() => {
    if (!catererData) {
      return;
    }

    const tableWarePayload = formatTableWare({
      isCharged: catererData.getCatererDetails.tablewareCharged,
      isDeclined: order.tablewareDeclined,
      total: 0,
      amount: catererData.getCatererDetails.tablewareAmount,
      people: order.headCount,
    });

    setTableWare(tableWarePayload);

    const foodCost = foodAndBeverageTotal(order.cartDetails);

    setIsMandatoryTip(
      catererData.getCatererDetails.driverTipFlag === CATERER_DRIVER_TIP_TYPE.mandatory,
    );
    if (catererData.getCatererDetails.driverTipFlag === CATERER_DRIVER_TIP_TYPE.mandatory) {
      const newTip =
        catererData.getCatererDetails.driverTipType === ORDER_TIP_TYPE.percentage
          ? getPercentValue(foodCost * catererData.getCatererDetails.driverTip)
          : catererData.getCatererDetails.driverTip;
      setDriverTipAmount(newTip);
      setDriverTipType(catererData.getCatererDetails.driverTipType);
      setValue("percentageTip", catererData.getCatererDetails.driverTip);
    } else {
      const newTip =
        order.driverTipType === ORDER_TIP_TYPE.percentage
          ? getPercentValue(foodCost * order.tipPercentage)
          : order.driverTip;

      if (order.driverTipType === ORDER_TIP_TYPE.percentage) {
        setValue("percentageTip", order.tipPercentage);
      }
      setDriverTipType(order.driverTipType);
      setDriverTipAmount(newTip);
    }

    setCartCost(foodCost);
    setCaterCash((catererData.getCatererDetails.caterCash * foodCost) / 100 || 0);
  }, [catererData]);

  const [getDeliveryFeeRequest] = useLazyQuery(GET_DELIVERY_FEE, {
    onCompleted(response) {
      if (!response.getDeliveryFee) {
        return;
      }
      setValue("deliveryFee", response.getDeliveryFee.deliveryFee);
    },
  });

  useQuery(GET_CONVENIENCE_FEE, {
    variables: {
      totalCost: cartCost + tableWare.value,
    },
    onCompleted(response) {
      setValue("convenienceFee", response.getConvenienceFee.feeTotal);
    },
  });

  const [checkCatererAvailability] = useLazyQuery(CHECK_AVL_DATE);

  async function onSubmit({
    street,
    date,
    contactName,
    location,
    contactPhone,
    orderType,
    suit,
    instruction,
    coordinates,
    city,
    zip,
    headCount,
    cardId,
    isSaveAddress,
    discount,
    addressId,
    percentageTip,
    deliveryFee,
  }) {
    const res = await checkCatererAvailability({
      variables: {
        data: {
          catererId: catererData.getCatererDetails._id,
          deliveryDate: date,
          deliveryDay: moment(date).format("dddd"),
          timeZone: TIME_ZONE,
          preprationTime: catererData.getCatererDetails.preprationTime,
          deliveryTime: convertTimeFormat(date),
          locationRadius: parseFloat(
            calcDistanceNew(coordinates, catererData.getCatererDetails.location),
          ),
        },
      },
    });

    if (!res.data.chkCatererAvbl.status) {
      const isDate =
        res.data.chkCatererAvbl.message === "Caterer is not available in this date/time";

      setError(isDate ? "date" : "street", {
        type: "custom",
        message: res.data.chkCatererAvbl.message,
      });
      toggleSnackbar({
        status: true,
        message: res.data.chkCatererAvbl.message,
        variant: "error",
      });
      return;
    }

    if (!cardId) {
      return toggleSnackbar({
        status: true,
        message: "Add a credit card to continue with your order.",
        variant: "error",
      });
    }

    try {
      const orderPayload = {
        orderStatus: isEdit ? order.orderStatus : "New",
        orderClient: "Web",
        orderPlaced: true,
        cardId,
        headCount: +headCount,
        salesTaxPercentage: salesTaxPercent,
        orderId,
        customerId,
        customerName,
        timeZone: TIME_ZONE,
        orderDate: moment(),
        saveAddress: isSaveAddress,
        dateScheduled: date,
        deliveryDate: date,
        deliveryTime: convertTimeFormat(date),
        deliveryMode: orderType,
        deliveryFee,
        discountAmt: discount,
        location,
        street,
        locationName: street,
        locWithStreet: street,
        suit: suit,
        contactPerson: contactName,
        contactNo: contactPhone,
        instruction: instruction,
        lat: coordinates[1],
        lng: coordinates[0],
        zip: zip,
        city: city,
        caterCashTotal: caterCash, // TODO: test what is it
        caterCash: catererData.getCatererDetails.caterCash,
        catererSlug: order.catererSlug,
        catererId: order.catererId,
        deliveryDay: moment(date).format("dddd"),
        preprationTime: catererData.getCatererDetails.preprationTime,
        skpDteFlag: true,
        locationRadius: parseFloat(
          calcDistanceNew(coordinates, catererData.getCatererDetails.location),
        ),
        addressId,
        tablewareDeclined: order.tablewareDeclined,
        browserName: checkBrowser(navigator.userAgent),
        userAgent: navigator.userAgent,
        isEditedOrder: isEdit,
        driverTip: driverTipAmount,
        driverTipType,
        tipPercentage: percentageTip ? percentageTip : null,
        grandTotal: totalCost,
      };

      const cartPayload = order.cartDetails.map(({ _id, ...item }) => ({
        ...item,
        orderId,
        cartId: item.cartId || _id,
      }));

      const orderResponse = await createNewOrderRequest({
        variables: {
          order: orderPayload,
          cart: cartPayload,
        },
      });

      toggleSnackbar({
        status: true,
        message: "Order placed successfully",
        variant: "success",
      });

      dispatch(isEdit ? clearEditOrder() : clearDraftOrder());
      if (userType === "Admin") {
        return navigate(ROUTES.orders);
      }
      navigate(ROUTES.confirmedOrder, {
        state: {
          catererName: catererData.getCatererDetails.businessName,
          deliveryDate: orderPayload.deliveryDate,
          deliveryTime: orderPayload.deliveryTime,
          caterCashTotal: orderPayload.caterCashTotal,
          id: orderResponse.data.createNewOrder._id,
        },
      });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  function onSelectLocation({ city, zip, coordinates }: ISearchLocationPayload) {
    setValue("zip", zip);
    if (!zip) {
      setError("zip", {
        type: "custom",
        message: "Please select correct street or enter zip code manually",
      });
    }
    trigger("zip");
    setValue("city", city);
    setValue("coordinates", coordinates);
    if (coordinates.length && dateWatcher) {
      onCheckAvailability(dateWatcher.toString(), coordinates);
    }
    onUpdateOrder({
      lat: coordinates[1],
      lng: coordinates[0],
      zip: zip,
      city,
    });
  }

  function onChangeCard(card) {
    setValue("cardId", card);
    onUpdateOrder({
      cardId: card,
    });
  }

  function onChangePercentageTip(value) {
    setValue("percentageTip", value);
    setValue("customTip", null);

    const newTip = value ? getPercentValue(cartCost * value) : 0;

    setDriverTipAmount(newTip);
    setDriverTipType(ORDER_TIP_TYPE.percentage);

    onUpdateOrder({
      driverTip: newTip,
      tipPercentage: value,
      driverTipType: ORDER_TIP_TYPE.percentage,
    });
  }

  function onChangeCustomTip(value) {
    const newTip = value || 0;

    setValue("customTip", newTip);
    setValue("percentageTip", null);

    setDriverTipType(ORDER_TIP_TYPE.custom);
    setDriverTipAmount(newTip);

    onUpdateOrder({
      driverTip: newTip,
      tipPercentage: 0,
      driverTipType: ORDER_TIP_TYPE.custom,
    });
  }

  function onUpdateOrder(params: Partial<IOrderPayload>) {
    dispatch(updateAction(params));
  }

  async function onCheckAvailability(date: string, coordinates: [number, number]) {
    if (!catererData || orderTypeWatcher !== ORDER_DELIVERY_MODE.delivery) {
      return;
    }

    const response = await checkCatererAvailability({
      variables: {
        data: {
          catererId: catererData.getCatererDetails._id,
          deliveryDate: date,
          deliveryDay: moment(date).format("dddd"),
          timeZone: TIME_ZONE,
          preprationTime: catererData.getCatererDetails.preprationTime,
          deliveryTime: convertTimeFormat(date),
          locationRadius: parseFloat(
            calcDistanceNew(coordinates, catererData.getCatererDetails.location),
          ),
        },
      },
    });

    if (!response.data.chkCatererAvbl) {
      return;
    }
    const data = response.data.chkCatererAvbl;

    const field =
      data.message === "Caterer is not available in this date/time"
        ? "date"
        : data.message === "Caterer is not available in this address you selected"
        ? "street"
        : "success";

    toggleSnackbar({
      status: true,
      message: data.message,
      variant: field !== "success" ? "error" : "success",
    });

    if (field !== "success") {
      setError(field, {
        type: "custom",
        message: data.message,
      });
    }
  }

  function onUpdateForm(name, value) {
    const updateMap = {
      instruction: "instruction",
      date: "deliveryDate",
      contactName: "contactPerson",
      contactPhone: "contactNo",
      suit: "suit",
      location: "location",
      street: "street",
    };

    if (name === "date" && coordsWatcher.length) {
      onCheckAvailability(value, coordsWatcher);
    }

    const type = updateMap[name];
    if (!type) {
      return;
    }
    if (name === "date") {
      return onUpdateOrder({ [type]: value ? value.toISOString() : "" });
    }
    onUpdateOrder({ [type]: value });
  }

  function onUpdateDeliveryMode(deliveryMode) {
    if (deliveryMode === ORDER_DELIVERY_MODE.pickup) {
      setDriverTipAmount(0);
      setValue("percentageTip", 0);
      setValue("customTip", 0);
    }
    onUpdateOrder({ deliveryMode });

    if (!catererData) {
      return;
    }
    getDeliveryFeeRequest({
      variables: {
        catererId: catererData.getCatererDetails._id,
        totalCost: cartCost,
        orderType: deliveryMode,
        zipCode: zipWatcher,
      },
    });
  }

  function onSubmitClick(e) {
    if (isSubmitLoading) {
      return;
    }
    return handleSubmit(onSubmit)();
  }

  return {
    onUpdateForm,
    onUpdateDeliveryMode,
    isEdit,
    userType,
    control,
    cardList,
    card: {
      value: cardWatcher,
      onChangeCard,
    },
    isSubmitLoading,
    isSaveAddress: isSaveAddressWatcher,
    orderProps: {
      suit: suitWatcher,
      contactName: contactNameWatcher,
      instruction: instructionWatcher,
      date: dateWatcher,
      street: streetWatcher,
      zip: zipWatcher,
      orderType: orderTypeWatcher,
      convenienceFee: convenienceFeeWatcher,
      priceInfo: {
        tableWare: tableWare.label,
        deliveryFee: deliveryFeeWatcher,
        cartCost: cartCost,
        totalCost: totalCost,
        convenienceFee: convenienceFeeWatcher,
        promoCodeDiscount: discountWatcher,
        headCount: headCountWatcher,
        caterCash,
        isMandatoryTip,
      },
      salesTax: {
        percent: salesTaxPercent,
        amount: taxCost,
      },
    },
    tip: {
      isMandatoryTip,
      value: driverTipAmount,
      percentageTip: percentageTipWatcher,
      onChangePercentageTip,
      onChangeCustomTip,
    },
    taxExempt,
    order,
    onSubmit: onSubmitClick,
    onSelectLocation,
  };
}
