import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Button, Tooltip, IconButton } from "@mui/material";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";

import SideMenu from "../../components/organisms/sidebar";
import CustomerHeader from "../../Modules/Common/customerHeader";
import OrderStatus from "../../Modules/Orders/orderStatus";
import RefundView from "../../Modules/Orders/refundView";
import RefundModal from "../../Modules/Orders/refundModal";
import { userDeatils, toggleSnackbar } from "../../ReactiveVariables/index";
import { GET_ONE_ORDER, GET_DELIVERY_FEE } from "../../Graphql/queries";
import { CREATE_ORDER, DELETE_CART } from "../../Graphql/mutations";
import {
  formatter,
  driverTipCalculator,
  salesTaxCalculator,
  calculateCaterCash,
  pricePerHead,
  calculateTablewareTotal,
  tablewareFormatter,
} from "../../Modules/Common/commonUtils";
import MobileHeader from "../../Modules/Common/mobileHeader";
import LoggedHeader from "../../Modules/Common/loggedHeader";
import { useAppDispatch } from "@services/redux";
import { updateEditOrder } from "@services/redux/order";
import { ViewOrderLoader } from "./view-order-loader";
import { ViewOrderTitle } from "./view-order-title";
import { ViewOrderCustomerAddress } from "./view-order-customer-address";
import { CustomItemForm } from "./custom-item-form";
import { useViewOrderState } from "./view-order.state";

export function ViewOrder() {
  const { id } = useParams();
  const location = useLocation();
  const user = useReactiveVar(userDeatils);
  const [totalPrice, setTotalPrice] = useState(0);
  const [initatedRefund, setInitatedRefund] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { orderValues, isOrderLoading, isCartSubmitting, onCreateCustomOrder } =
    useViewOrderState();

  const [createCart] = useMutation(CREATE_ORDER, {
    refetchQueries: [
      {
        query: GET_ONE_ORDER,
        variables: {
          customerId:
            user?.data?.currentUserDetails?._id ||
            user?.data?.createUserIdentity?._id ||
            user?.data?.login?._id,
          orderId: id,
        },
      },
    ],
  });

  const [deleteCartItem] = useMutation(DELETE_CART, {
    onCompleted: (res) => {
      handleCustomItemSubmit();
    },
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const userType =
    user?.data?.login?.userType ||
    user?.data?.currentUserDetails?.userType ||
    user?.data?.createUserIdentity?.userType ||
    user?.data?.createCaterer?.userType;

  const totalFoodAndBeverage = totalPrice || orderValues?.getOneOrder?.totalPrice;
  const catererId = orderValues?.getOneOrder?.catererId;

  const zip = orderValues?.getOneOrder?.zip;

  const { data: deliveryFeeResult, refetch: refetchDeliveryFee } = useQuery(GET_DELIVERY_FEE, {
    variables: {
      catererId,
      totalCost: totalFoodAndBeverage,
      orderType: orderValues?.getOneOrder?.deliveryMode,
      zipCode: zip,
    },
    skip: !totalFoodAndBeverage,
  });

  const tablewareDeclined = orderValues?.getOneOrder?.tablewareDeclined || false;
  const tablewareCharged = orderValues?.getOneOrder?.tablewareCharged || false;

  // If tableware is declined, set tableware amount to 0
  const tablewareAmount = tablewareDeclined ? 0 : orderValues?.getOneOrder?.tablewareAmount || 0;
  const tablewareTotal = tablewareCharged
    ? calculateTablewareTotal(tablewareAmount, orderValues?.getOneOrder?.headCount)
    : 0;

  const handleCustomItemSubmit = async () => {
    let totalFoodAndBeverage = totalPrice;
    let driverTipTypeFlag;
    if (orderValues?.getOneOrder?.driverTipType === "Percentage") {
      driverTipTypeFlag = false;
    } else {
      driverTipTypeFlag = true;
    }
    const deliveryFee = deliveryFeeResult?.getDeliveryFee?.deliveryFee || 0;
    let salesTax = salesTaxCalculator(
      totalFoodAndBeverage + deliveryFee + tablewareTotal,
      orderValues?.getOneOrder?.salesTaxPercentage,
    );
    let caterCashTotal = calculateCaterCash(
      totalFoodAndBeverage,
      orderValues?.getOneOrder?.catererDetails,
    );
    let drivertipValue = parseFloat(
      driverTipCalculator(
        orderValues?.getOneOrder,
        orderValues?.getOneOrder?.catererDetails,
        totalFoodAndBeverage + deliveryFee + tablewareTotal,
      ),
    );
    let grandTotalValue = totalFoodAndBeverage + drivertipValue + deliveryFee + salesTax;
    let request = {
      catererId,
      orderId: id,
      totalPrice: totalFoodAndBeverage,
      driverTip: drivertipValue,
      grandTotal: grandTotalValue,
      deliveryFee: deliveryFee,
      orderStatus: orderValues?.getOneOrder?.orderStatus,
      orderPlaced: true,
      salesTaxAmount: salesTax,
      totalPerHead: pricePerHead(grandTotalValue, orderValues?.getOneOrder?.headCount),
      caterCashTotal: caterCashTotal,
    };
    try {
      createCart({
        variables: {
          data: request,
        },
      }).catch((err) => {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };

  function handleDelete(id, order) {
    setTotalPrice(orderValues?.getOneOrder?.totalPrice - order.cartTotalPrice);
    try {
      deleteCartItem({
        variables: {
          cartId: id,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Item removed from the cart successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  const setupEditOrder = () => {
    const order = orderValues?.getOneOrder;
    if (!order) {
      return;
    }
    const orderId = order._id;
    dispatch(updateEditOrder(order));

    navigate(`/caterers/${order.catererSlug}`, { state: { data: order } });
  };

  let prevValue = null;

  return (
    <div>
      {/* header for Customer login  */}
      {userType === "Customer" ? (
        <MobileHeader selected={"Orders"} />
      ) : (
        <LoggedHeader title="Orders" />
      )}
      <CustomerHeader
        title={
          userType === "Caterer"
            ? orderValues?.getOneOrder?.orderNo &&
              "#" +
                orderValues?.getOneOrder?.orderNo +
                " for " +
                orderValues?.getOneOrder?.catererDetails?.businessName +
                ", " +
                orderValues?.getOneOrder?.catererDetails?.locationName
            : userType === "Customer"
            ? orderValues?.getOneOrder?.orderNo &&
              "Order #" +
                orderValues?.getOneOrder?.orderNo +
                " from " +
                orderValues?.getOneOrder?.catererDetails?.businessName
            : orderValues?.getOneOrder?.orderNo &&
              "#" +
                orderValues?.getOneOrder?.orderNo +
                " for " +
                orderValues?.getOneOrder?.catererDetails?.businessName +
                ", " +
                orderValues?.getOneOrder?.catererDetails?.locationName
        }
      />
      {/* Side menu for different options for the Customer */}
      <SideMenu
        selected={
          location?.state === "caterer"
            ? "Caterers"
            : location?.state === "customers"
            ? "Customers"
            : "Orders"
        }
        user={userType === "Customer" ? "customer" : userType === "Caterer" ? "caterer" : "admin"}
      />
      <div className="contentWrap contentWrap-order-view" style={{ backgroundColor: "#FAFAFB" }}>
        <div className="container">
          <div className="row">
            <div style={{ margin: "0 auto" }} className="col-lg-8 col-md-12">
              {userType === "Admin" ? (
                <Link
                  to={
                    location?.state === "caterer"
                      ? `/admincatererlist/${catererId}`
                      : location?.state === "customers"
                      ? `/customers/${orderValues?.getOneOrder?.customerId}`
                      : "/orders"
                  }
                  state={location?.state}
                  className="back-link-order">
                  <img src="../assets/images/back-org.svg" className="img-fluid" alt="Back" />
                  Back to orders
                </Link>
              ) : (
                <Link
                  to={`/orders`}
                  state={{ order: location?.state?.status === "completed" ? "completed" : "" }}
                  className="back-link-order">
                  <img src="../assets/images/back-org.svg" className="img-fluid" alt="Back" />
                  Back to orders
                </Link>
              )}
              {isOrderLoading ? (
                <ViewOrderLoader />
              ) : (
                <>
                  <ViewOrderTitle
                    deliveryDate={orderValues?.getOneOrder?.deliveryDate}
                    deliveryTime={orderValues?.getOneOrder?.deliveryTime}
                    deliveryMode={orderValues?.getOneOrder?.deliveryMode}
                    orderStatus={orderValues?.getOneOrder?.orderStatus}
                    isRefunded={orderValues?.getOneOrder?.isRefunded}
                  />
                  <div className="order-view">
                    <ViewOrderCustomerAddress
                      avatarUrl={orderValues?.getOneOrder?.customerDetails?.imageUrl}
                      contactPerson={orderValues?.getOneOrder?.contactPerson}
                      name={
                        orderValues?.getOneOrder?.customerDetails?.firstName
                          ? `${orderValues?.getOneOrder?.customerDetails?.firstName} ${orderValues?.getOneOrder?.customerDetails?.lastName}`
                          : ""
                      }
                      email={orderValues?.getOneOrder?.customerDetails?.email}
                      phone={
                        orderValues?.getOneOrder?.contactNo ||
                        orderValues?.getOneOrder?.customerDetails?.phoneNumber
                      }
                      location={orderValues?.getOneOrder?.location}
                      suit={orderValues?.getOneOrder?.suit}
                      street={orderValues?.getOneOrder?.street}
                      zip={orderValues?.getOneOrder?.zip}
                    />
                    {orderValues?.getOneOrder?.instruction && (
                      <div className="od-common-box">
                        <h6 className="h6">Instructions</h6>
                        <p>{orderValues?.getOneOrder?.instruction}</p>
                      </div>
                    )}
                    {userType !== "Customer" &&
                      (orderValues?.getOneOrder?.orderStatus === "New" ||
                        orderValues?.getOneOrder?.orderStatus === "Scheduled" ||
                        orderValues?.getOneOrder?.orderStatus === "Completed") && (
                        <div className="od-common-box">
                          <h6 className="h6">Payment Details</h6>
                          <p style={{ fontSize: "14px", color: "#000" }}>
                            {orderValues?.getOneOrder?.orderStatus === "Completed"
                              ? "Paid with"
                              : "Selected Payment"}{" "}
                            {orderValues?.getOneOrder?.cardDetails?.cardType} card{" "}
                            <span style={{ fontWeight: 700 }}>
                              XXXX XXXX XXXX{" "}
                              {orderValues?.getOneOrder?.cardDetails?.cardLastDigits &&
                                orderValues?.getOneOrder?.cardDetails?.cardLastDigits.slice(-5)}
                            </span>{" "}
                            {orderValues?.getOneOrder?.orderStatus === "Completed" && (
                              <span style={{ fontWeight: 700 }}>
                                on{" "}
                                {moment(orderValues?.getOneOrder?.dateCompleted).format(
                                  "MM/DD/YYYY",
                                )}
                              </span>
                            )}
                          </p>
                        </div>
                      )}
                    {userType === "Customer" &&
                      orderValues?.getOneOrder?.orderStatus === "Completed" && (
                        <div className="od-common-box">
                          <h6 className="h6">Payment Details</h6>
                          <p style={{ fontSize: "14px", color: "#000" }}>
                            Paid with {orderValues?.getOneOrder?.cardDetails?.cardType} card{" "}
                            <span style={{ fontWeight: 700 }}>
                              XXXX XXXX XXXX{" "}
                              {orderValues?.getOneOrder?.cardDetails?.cardLastDigits?.slice(-5)}
                            </span>{" "}
                            on{" "}
                            <span style={{ fontWeight: 700 }}>
                              {moment(orderValues?.getOneOrder?.dateCompleted).format("MM/DD/YYYY")}
                            </span>
                            {/* Selected Payment {orderValues?.getOneOrder?.cardDetails?.cardType}{" "}card{" "}
                    <span style={{ fontWeight: 700 }}>XXXX XXXX XXXX {orderValues?.getOneOrder?.cardDetails?.cardLastDigits && orderValues?.getOneOrder?.cardDetails?.cardLastDigits.slice(-5)}</span>{" "} */}
                          </p>
                        </div>
                      )}
                    {userType === "Customer" &&
                      (orderValues?.getOneOrder?.orderStatus === "New" ||
                        orderValues?.getOneOrder?.orderStatus === "Scheduled") && (
                        <div className="od-common-box">
                          <h6 className="h6">Payment Details</h6>
                          <p style={{ fontSize: "14px", color: "#000" }}>
                            Your {orderValues?.getOneOrder?.cardDetails?.cardType} card ending in{" "}
                            <span style={{ fontWeight: 700 }}>
                              {orderValues?.getOneOrder?.cardDetails?.cardLastDigits?.slice(-5)}
                            </span>{" "}
                            will not be charged until your order has been completed
                          </p>
                        </div>
                      )}
                    {orderValues?.getOneOrder?.orderStatus === "Cancelled" && (
                      <div className="od-common-box">
                        <h6 className="h6">Payment Details</h6>
                        <p style={{ fontSize: "14px", color: "#000" }}>
                          This order was cancelled on{" "}
                          <span style={{ fontWeight: 700 }}>
                            {moment(orderValues?.getOneOrder?.dateCancelled).format(
                              "ddd, MMM DD YYYY",
                            )}{" "}
                            at {moment(orderValues?.getOneOrder?.dateCancelled).format("LT")}
                          </span>{" "}
                        </p>
                      </div>
                    )}
                    {userType !== "Customer" &&
                      orderValues?.getOneOrder?.isRefunded &&
                      orderValues?.getOneOrder?.refundText && (
                        <div className="od-common-box">
                          <h6 className="h6">Reason for refund</h6>
                          <p style={{ fontSize: "14px", color: "#000" }}>
                            {orderValues?.getOneOrder?.refundText}
                          </p>
                        </div>
                      )}

                    <div className="review-order-item">
                      <h6 className="h6">Your Order</h6>
                      {orderValues?.getOneOrder?.cartDetails?.map((order, index) => {
                        return (
                          <div className="roi-single" key={index}>
                            <div className="d-flex justify-content-between">
                              <div className="col-md-9 roi-details">
                                <h6
                                  className={
                                    order?.itemName && order?.itemName.length > 1 && index > 0
                                      ? "mb-0 order-item-highlight"
                                      : "mb-0"
                                  }>
                                  {order?.itemName ?? "Menu removed by caterer"}
                                </h6>
                                {order?.addOns?.length > 0 && (
                                  <div className="roi-subaddon-wrap">
                                    {order?.addOns?.map((item, indexA) => {
                                      if (item.addOnTypeName !== prevValue) {
                                        prevValue = item.addOnTypeName;
                                        return (
                                          <div key={indexA}>
                                            <p className="roi-subaddon">
                                              {`${item?.addOnTypeName ?? "add on type removed"} : `}
                                              {`${item?.addOnName}`}
                                              {item?.subAddOns.length > 0 && <b> | </b>}
                                              {item?.subAddOns?.map((item, indexS) => (
                                                <span key={indexS}>
                                                  {`${item?.subAddOnName}`}
                                                  <b> / </b>
                                                </span>
                                              ))}
                                            </p>
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div key={index}>
                                            <p className="roi-subaddon">
                                              {" "}
                                              {`${item?.addOnName}`}
                                              {item?.subAddOns.length > 0 && <b> | </b>}
                                              {item?.subAddOns?.map((item, indexSA) => (
                                                <span key={indexSA}>
                                                  {`${item?.subAddOnName}`}
                                                  <b> / </b>
                                                </span>
                                              ))}
                                            </p>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                )}
                              </div>
                              <div className="col-md-3 roi-count-price text-end">
                                <div className="d-flex align-items-center justify-content-end">
                                  <span style={{ color: "#FD4E3B", flexShrink: 0 }}>
                                    {order?.quantity} X&nbsp;
                                  </span>
                                  {formatter.format(order?.totalPricePerUnit)}

                                  {userType !== "Customer" &&
                                    order?.cartType === "Custom" &&
                                    (orderValues?.getOneOrder?.orderStatus === "New" ||
                                      orderValues?.getOneOrder?.orderStatus === "Scheduled") && (
                                      <IconButton
                                        onClick={() => {
                                          handleDelete(order?._id, order);
                                        }}
                                        className="p-1 ms-1">
                                        <img
                                          src="../assets/images/cart-item-delete.svg"
                                          width="15px"
                                          className="img-fluid"
                                          alt="Delete Item"
                                        />
                                      </IconButton>
                                    )}
                                </div>
                              </div>
                            </div>
                            {order?.cartInstruction && (
                              <div className="roi-sp-instr">
                                {order?.cartType !== "Custom" && <h6>Special Instructions</h6>}
                                <p>{order?.cartInstruction}</p>
                              </div>
                            )}
                            <div></div>
                          </div>
                        );
                      })}
                    </div>

                    <CustomItemForm
                      orderStatus={orderValues?.getOneOrder?.orderStatus}
                      userType={userType}
                      onSubmit={onCreateCustomOrder}
                      isSubmitting={isCartSubmitting}
                    />

                    <div className="review-order-price">
                      <h6 className="h6">Price Details</h6>
                      <div className="other-charges">
                        {orderValues?.getOneOrder?.discountAmt ? (
                          <div className="other-charges-item">
                            <label>Discount</label>
                            <b>
                              {formatter.format(
                                orderValues?.getOneOrder?.discountAmt
                                  ? orderValues?.getOneOrder?.discountAmt
                                  : 0,
                              )}
                            </b>
                          </div>
                        ) : null}
                        <div className="other-charges-item">
                          <label>Subtotal</label>
                          {orderValues?.getOneOrder?.totalPrice && (
                            <b>{formatter.format(orderValues?.getOneOrder?.totalPrice)}</b>
                          )}
                        </div>
                        <div className="other-charges-item">
                          <label>Tableware</label>
                          {tablewareDeclined ? (
                            <b>No Tableware</b>
                          ) : (
                            <b>
                              <Tooltip
                                title="Tableware prices are set by the caterer"
                                placement="top">
                                <IconButton sx={{ padding: 0, marginRight: "5px", bottom: "1px" }}>
                                  <img
                                    src="../assets/images/icon-info.svg"
                                    className="img-fluid"
                                    alt="Delete Item"
                                  />
                                </IconButton>
                              </Tooltip>
                              {tablewareFormatter(tablewareTotal, tablewareCharged)}{" "}
                            </b>
                          )}
                        </div>
                        {orderValues?.getOneOrder?.deliveryMode != "Pickup Only" && (
                          <div className="other-charges-item">
                            <label>Delivery Fee</label>

                            {orderValues?.getOneOrder?.deliveryFee && (
                              <b>{formatter.format(orderValues?.getOneOrder?.deliveryFee)}</b>
                            )}
                          </div>
                        )}
                        <div className="other-charges-item">
                          <label>Service Fee</label>

                          <b>
                            {orderValues?.getOneOrder?.convenienceFee
                              ? formatter.format(orderValues?.getOneOrder?.convenienceFee)
                              : "$0"}
                          </b>
                        </div>
                        <div className="other-charges-item">
                          <label>{`${orderValues?.getOneOrder?.salesTaxPercentage}% Sales Tax`}</label>
                          <b>
                            {orderValues?.getOneOrder?.salesTaxAmount
                              ? formatter.format(orderValues?.getOneOrder?.salesTaxAmount)
                              : "$0"}
                          </b>
                        </div>
                      </div>

                      <div className="common-cart-viewbox common-cart-viewbox-tip">
                        <label>Tip for Driver</label>
                        <b>
                          {formatter.format(
                            orderValues?.getOneOrder?.driverTip
                              ? orderValues?.getOneOrder?.driverTip
                              : 0,
                          )}
                        </b>
                      </div>
                      {orderValues?.getOneOrder?.isRefunded && (
                        <div className="common-cart-viewbox common-cart-viewbox-tip">
                          <label>Refund</label>
                          {orderValues?.getOneOrder?.refundAmount && (
                            <b>-{formatter.format(orderValues?.getOneOrder?.refundAmount)}</b>
                          )}
                        </div>
                      )}
                      {orderValues?.getOneOrder?.isRefunded && (
                        <div className="common-cart-viewbox common-cart-viewbox-tip">
                          <label>CaterCash deduction</label>
                          {orderValues?.getOneOrder?.caterCashForRefund && (
                            <b>-{formatter.format(orderValues?.getOneOrder?.caterCashForRefund)}</b>
                          )}
                        </div>
                      )}
                      <div className="cart-total common-cart-viewbox">
                        <label>CaterCash Earned</label>
                        {orderValues?.getOneOrder?.caterCashTotal && (
                          <b>{formatter.format(orderValues?.getOneOrder?.caterCashTotal)}</b>
                        )}
                      </div>

                      <div className="cart-total common-cart-viewbox">
                        <label>Total</label>
                        {orderValues?.getOneOrder?.grandTotal && (
                          <b>{formatter.format(orderValues?.getOneOrder?.grandTotal)}</b>
                        )}
                      </div>

                      <div className="cart-price-perhead common-cart-viewbox">
                        <label>
                          Price Per Head (Head Count: {orderValues?.getOneOrder?.headCount})
                        </label>
                        {orderValues?.getOneOrder?.totalPerHead && (
                          <b>{formatter.format(orderValues?.getOneOrder?.totalPerHead)}/person</b>
                        )}
                      </div>

                      {userType === "Admin" && (
                        <div className="cart-price-perhead common-cart-viewbox">
                          <Button variant="contained" onClick={setupEditOrder}>
                            Edit Order
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            {(userType === "Caterer" || userType === "Admin") && (
              <div className="col-md-4">
                {userType === "Caterer" &&
                  orderValues?.getOneOrder?.orderStatus === "Completed" &&
                  !orderValues?.getOneOrder?.isRefunded && (
                    <RefundView setInitatedRefund={setInitatedRefund} orderValues={orderValues} />
                  )}
                {(userType === "Caterer" || userType === "Admin") && (
                  <OrderStatus id={id} orderValues={orderValues} />
                )}
              </div>
            )}

            {initatedRefund && (
              <div className="col-md-4">
                <RefundModal
                  initatedRefund={initatedRefund}
                  setInitatedRefund={setInitatedRefund}
                  orderData={orderValues?.getOneOrder}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
