import { ORDER_DELIVERY_MODE, ORDER_TIP_TYPE } from "@constants/order.constants";
import { IOrderPayload } from "./order.typings";
import { IServerOrderResponse } from "typings/order.api";

export function formatServerOrder(serverOrder: IServerOrderResponse): IOrderPayload {
  return {
    _id: serverOrder._id || "",
    cartDetails: serverOrder.cartDetails,
    uuid: serverOrder.uuid,
    addressId: serverOrder.addressId,
    orderStatus: serverOrder.orderStatus,
    orderClient: serverOrder.orderClient,
    cardId: serverOrder.cardId,
    orderId: serverOrder._id,
    headCount: serverOrder.headCount,
    deliveryFee: serverOrder.deliveryFee,
    deliveryMode: serverOrder.deliveryMode || ORDER_DELIVERY_MODE.delivery,
    deliveryDate: serverOrder.deliveryDate,
    discountAmt: serverOrder.discountAmt,
    customerId: serverOrder.customerId,
    catererId: serverOrder.catererId,
    catererSlug: serverOrder.catererSlug,
    catererName: serverOrder.catererDetails.businessName,
    street: serverOrder.street || serverOrder.locationName,
    locationName: serverOrder.locationName,
    lat: serverOrder.lat,
    lng: serverOrder.lng,
    city: serverOrder.city,
    zip: serverOrder.zip,
    location: serverOrder.location,
    suit: serverOrder.suit,
    instruction: serverOrder.instruction,
    contactNo: serverOrder.contactNo,
    contactPerson: serverOrder.contactPerson,
    tablewareAmount: serverOrder.tablewareAmount,
    tablewareCharged: serverOrder.tablewareCharged,
    tablewareDeclined: serverOrder.tablewareDeclined,
    tablewareTotal: serverOrder.tablewareTotal,
    salesTaxAmount: serverOrder.salesTaxAmount,
    salesTaxPercentage: serverOrder.salesTaxPercentage,
    driverTip: serverOrder.driverTip,
    driverTipType: serverOrder.driverTipType as any,
    tipPercentage: fixTipPercentage({
      tipPercentage: serverOrder.tipPercentage,
      driverTipType: serverOrder.driverTipType,
      totalPrice: serverOrder.totalPrice,
      driverTip: serverOrder.driverTip,
    }),
    grandTotal: serverOrder.grandTotal,
    totalPrice: serverOrder.totalPrice,
    convenienceFee: serverOrder.convenienceFee,
  };
}

function fixTipPercentage({ tipPercentage, driverTipType, totalPrice, driverTip }) {
  if (!!tipPercentage || driverTipType !== ORDER_TIP_TYPE.percentage) {
    return tipPercentage;
  }

  return (driverTip / totalPrice) * 100;
}
