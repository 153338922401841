import React, { useEffect, useRef } from "react";
import { Button } from "@mui/material";
import DialogBox from "../../../Modules/Common/dialogBox";
import { PaymentCardForm } from "./components/payment-card-form/payment-card-form";
import { PaymentCardItem } from "./components/payment-card-item";
import { usePaymentCardState } from "./payment-card.state";

interface Card {
  _id: string;
  defaultCard?: boolean;
}

export function PaymentCard(props) {
  const {
    onDeleteRequest,
    onDelete,
    isOpenDeleteModal,
    setDeleteModal,
    onSubmit: onSubmit2,
    isOpenForm,
    onOpenForm,
    onCloseForm,
    onEditCard,
    selectedCard,
    onSetDefaultCard,
  } = usePaymentCardState();

  const previousCardsRef = useRef<Card[] | undefined>([]);
  const [dataReady, setDataReady] = React.useState(false);

  // Setting the default card
  useEffect(() => {
    const cards: Card[] | undefined = props?.listCard?.listCard?.data;
    const firstCard: Card | null = cards && cards.length > 0 ? cards[0] : null;
    const defaultCard: Card | null = cards ? cards.find(card => card?.defaultCard) || firstCard : null;

    if (cards && cards.length > 0 && !dataReady) {
      setDataReady(true);
    }

    // Check if it's the initial load and no card is selected
    if (cards && !props?.checkedCard && !selectedCard) {
      if (defaultCard && defaultCard._id) {
        props?.setCheckedCard(defaultCard._id);
      }
    }

    // Detect new card by comparing the current cards with the previous ones
    if (cards && previousCardsRef.current && previousCardsRef.current.length !== cards.length) {
      const previousCards = previousCardsRef.current;
      const newCard = cards.find(card => !previousCards.some(prevCard => prevCard._id === card._id));

      if (newCard) {
        props?.setCheckedCard(newCard._id);
      }
    }

    // Update the ref to the current cards for future comparison
    previousCardsRef.current = cards;

  }, [props?.listCard?.listCard?.data, props?.checkedCard, selectedCard, dataReady]);

  return (
    <div className="added-card">
      {isOpenDeleteModal && (
        <DialogBox
          setOpenDialog={setDeleteModal}
          handleDelete={onDeleteRequest}
          openDialog={isOpenDeleteModal}
          title="Delete"
          discription="Are you sure you want to delete this credit card?"
        />
      )}
      <div className="saved-card">
        <h6 className="h6">{props?.reviewOrderScreen ? "Select Payment" : "Saved Cards"}</h6>
        {props?.listCard?.listCard?.data?.map((item, key) => {
          return (
            <PaymentCardItem
              key={key}
              item={item}
              isActive={props?.checkedCard === item?._id} // Compare checkedCard to set active
              onCheck={props?.setCheckedCard}
              onEdit={onEditCard}
              onDelete={onDelete}
            />
          );
        })}
      </div>

      {!selectedCard && (
        <Button className="add-card-btn" onClick={onOpenForm}>
          <img src="../assets/images/red_plus.svg" className="img-fluid" alt="Icon" />
          Add new card
        </Button>
      )}

      <PaymentCardForm
        isOpen={isOpenForm}
        activeItem={selectedCard}
        onSubmit={onSubmit2}
        onCancel={onCloseForm}
      />
    </div>
  );
}
