import React from 'react';
import LoginHeader from "../../Login/LoginHeader";
import Footer from "../../Common/footer";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet";

export default function TermsCondition() {
  return (
    <div>
      <Helmet>
        <title>CaterPlace - Corporate Catering</title>
        <meta name="Keywords" />
        <meta name="description" content="What you are agreeing to by using the CaterPlace Platform" />
      </Helmet>
      <LoginHeader />
      <section className="about-banner overlay">
        <div className="container">
          <div className="about-banner-cnt m-auto" style={{ maxWidth: 'fit-content' }}>
            <h2 className="h2 text-center">Terms of Use</h2>
          </div>
        </div>
      </section>
      <section className="general-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 text-center m-auto">
              <h2 className="h2">TERMS OF USE AGREEMENT
                <h6 className="h6">Updated October 4th, 2024</h6>
              </h2>
            </div>
            <div className="col-lg-9 m-auto">
              <p className="terms_of_use_p">
                PLEASE READ THIS TERMS OF USE AGREEMENT CAREFULLY. THIS TERMS OF USE AGREEMENT ("AGREEMENT") CONSTITUTES A LEGAL AND BINDING AGREEMENT BETWEEN YOU AND [CATERPLACE LLC, A COMPANY FORMED UNDER THE LAWS OF THE STATE OF DELAWARE], AND ITS SUBSIDIARIES AND AFFILIATED COMPANIES (COLLECTIVELY "CATERPLACE," "WE," "US," OR "OUR").
              </p>
              <p className="terms_of_use_p">
                IMPORTANT: PLEASE REVIEW THE DISPUTE RESOLUTION SECTION (SECTION 2) CAREFULLY. IT REQUIRES YOU TO RESOLVE DISPUTES WITH CATERPLACE THROUGH INDIVIDUAL BINDING ARBITRATION. YOU AGREE THAT YOU AND CATERPLACE EACH WAIVE THE RIGHT TO A TRIAL BY JURY AND TO PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING AGAINST CATERPLACE.
              </p>
              <h2>1. Acceptance of This Agreement</h2>
              <p className="normal_p">
                By accessing or using any CaterPlace website (www.caterplace.com), mobile sites, web-based applications, or any software supplied by CaterPlace, or by creating an account, you represent and warrant that:
              </p>
              <p className="p_left normal_p">
                (a) You are of legal age in your jurisdiction to form a binding contract;<br />
                (b) You will comply with all applicable laws and regulations;<br />
                (c) If acting on behalf of an organization, you have authority to bind that organization to this Agreement; and<br />
                (d) You have read, understood, and agreed to this Agreement.
              </p>
              <h2>2. Dispute Resolution</h2>
              <p className="terms_of_use_p">
                You agree that all disputes between you and CaterPlace will be resolved through binding arbitration on an individual basis. You waive your right to a trial by jury or to participate in any class action. Exceptions to arbitration include small claims court or equitable relief related to intellectual property rights.
              </p>
              <h2>3. Vendors and Delivery Service Providers</h2>
              <p className="normal_p">
                CaterPlace acts as a technology platform connecting you with independent Vendors and third-party delivery services. CaterPlace is not responsible for the quality, handling, or delivery of orders and disclaims liability for issues arising between you and Vendors or delivery services.
              </p>
              <h2>4. User Account</h2>
              <p className="normal_p">
                By creating an account, you agree to be solely responsible for its use and security. You must provide accurate information and notify CaterPlace of unauthorized use. CaterPlace reserves the right to suspend or terminate accounts for any reason.
              </p>
              <h2>5. Payments and Rewards</h2>
              <p className="normal_p">
                You authorize CaterPlace to charge the payment method specified at the time of purchase. CaterCash points earned through purchases or promotions expire 365 days from the date of receipt and hold no cash value outside of the platform.
              </p>
              <h2>6. License to Access CaterPlace Services</h2>
              <p className="normal_p">
                You are granted a limited, revocable license to access and use CaterPlace in compliance with applicable laws and this Agreement.
              </p>
              <h2>7. Legal Compliance and Privacy</h2>
              <p className="normal_p">
                CaterPlace may access and disclose your information as required by law. Please refer to our Privacy Policy for details on how we collect and use your data.
              </p>
              <h2>8. Disclaimer of Warranties; Limitation of Liability</h2>
              <p className="normal_p">
                CaterPlace provides services "as-is" without any warranties. CaterPlace disclaims all liability for damages arising from the use of the Services, including indirect or consequential damages.
              </p>
              <h2>9. Third-Party Links and Services</h2>
              <p className="normal_p">
                CaterPlace is not responsible for third-party content, products, or services accessed through the platform.
              </p>
              <h2>10. Modifications and Termination</h2>
              <p className="normal_p">
                CaterPlace reserves the right to modify or discontinue services at any time. We may terminate your access without notice.
              </p>
              <h2>11. Governing Law</h2>
              <p className="normal_p">
                Except for disputes governed by arbitration, this Agreement is governed by the laws of the State of New York.
              </p>
              <h2>12. Contact</h2>
              <p className="normal_p">
                For any questions or concerns, please email us at <a href="mailto:support@caterplace.com" style={{ color: "#FD4E3B" }}>support@caterplace.com</a>.
              </p>
              <p>By using our service you also agree to the expanded version of this agreement. Click <a target="_blank" rel="noreferrer" href="https://caterplace-images-production.s3.amazonaws.com/tos/CATERPLACE_EXPANDED_TERMS_OF_USE_AGREEMENT_10_4_24.pdf">here</a> to see it.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
