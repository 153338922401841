import { useMemo } from "react";
import { getPercentValue } from "../order-format.utils";

interface IOrderCalculationHook {
  salesTaxPercent: number;
  cartCost: number;
  deliveryFee: number;
  driverTip: number;
  tableware: number;
  discount: number;
  convenienceFee: number;
}

export function useOrderCalculation({
  salesTaxPercent,
  cartCost,
  deliveryFee,
  driverTip,
  tableware,
  discount,
  convenienceFee
}: IOrderCalculationHook) {
  const taxCost = useMemo(() => {
    return getPercentValue((cartCost + tableware + deliveryFee - discount) * salesTaxPercent);
  }, [salesTaxPercent, cartCost, tableware, discount, deliveryFee]);

  const totalCost = useMemo(() => {
    return cartCost + deliveryFee + driverTip + taxCost + tableware + convenienceFee - discount;
  }, [cartCost, deliveryFee, driverTip, taxCost, tableware, convenienceFee, discount]);

  return {
    taxCost,
    totalCost,
  };
}
