// this page is for listin the menus created
import React, { useState } from "react";
import { Table, Select, MenuItem, Button } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useNavigate, Link, useParams } from "react-router-dom";
import { userDeatils, toggleSnackbar, authVar } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import TableRow from "@mui/material/TableRow";
import {
  LIST_CATERER,
  LIST_CATEGORY,
  LIST_MENU,
  MENU_COUNT,
  LIST_CATEGORY_SORT,
} from "../../Graphql/queries";
import { DELETE_MENU } from "../../Graphql/mutations";
import SideMenu from "../../components/organisms/sidebar";
import LoggedHeader from "../Common/loggedHeader";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import Menu from "@mui/material/Menu";
import { AddMenu } from "./MenuAction/AddMenu";
import ImportMenu from "./MenuAction/ImportMenu";
import DialogBox from "../Common/dialogBox";
export default function MenuList(props) {
  const user = useReactiveVar(userDeatils);
  const navigate = useNavigate();
  const { id } = useParams();
  const [openAddMenu, setOpenAddMenu] = useState(false);
  const [openImportMenu, setOpenImportMenu] = useState(false);
  const [openCategorySort, setOpenCategorySort] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [category, setCategory] = useState(null);
  const [getLocId, setGetLocId] = useState("");
  const [search, setSearch] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [newCategory, setNewCategory] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDupe, setIsDupe] = useState(false);
  const formatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }); // currency formater
  const open = Boolean(anchorEl);
  const catererId =
    user?.data?.login?.catererId ||
    user?.data?.currentUserDetails?.catererId ||
    user?.data?.createCaterer?.catererId ||
    id;
  const userType =
    user?.data?.login?.userType ||
    user?.data?.currentUserDetails?.userType ||
    user?.data?.createUserIdentity?.userType ||
    user?.data?.createCaterer?.userType;
  const { data: categoryData } = useQuery(LIST_CATEGORY, {
    variables: { sortField: "categoryTitle" },
    onCompleted: (res) => {
      setNewCategory(res);
    },
  });
  const { data: categorySortData } = useQuery(LIST_CATEGORY_SORT, {
    variables: { catererId: catererId, sortField: "CategoryOrder" },
    skip: !catererId,
    onCompleted: (res) => {
      setNewCategory(res);
    },
  });

  const menuVariables = {
    catererId: catererId,
    search: search,
    category: category,
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    adminFlg: true,
  };
  const { data: menuItems, loading } = useQuery(LIST_MENU, { variables: menuVariables });
  const catererData = menuItems?.listMenu?.catererData;
  // handling pagenation
  const handleChangePage = (e, pages) => {
    setPage(pages);
  };

  const [DeleteMenuItem] = useMutation(DELETE_MENU, {
    refetchQueries: ["ListMenu", "ListCategoryOrder"],
    onCompleted: (res) => {
      setSelectedItem(null);
    },
  });
  // change number of lisiting in a page
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  // getting catagory filter
  const handleChange = (e) => {
    setCategory(e.target.value);
  };
  // opening menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // close menu
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenSort = () => setOpenCategorySort(true);
  const handleCloseSort = () => setOpenCategorySort(false);
  // delete menu function
  function handleDelete() {
    try {
      DeleteMenuItem({ variables: { menuId: selectedItem?._id } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Menu item deleted successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          if (message === "Not authenticated") {
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            userType === "Admin"
              ? navigate("/admin", { state: "noAuth" })
              : navigate("/login", { state: "noAuth" });
          } else {
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  return (
    <div>
      {/* header for changing location and getting the id */}
      {props.userType !== "Admin" && <LoggedHeader setGetLocId={setGetLocId} title="Menu" />}
      {/* keeping the side bar selected */}
      {props.userType !== "Admin" && <SideMenu selected="Menu" />}
      {/* open drawer for adding and editing menu item */}
      {openAddMenu && (
        <AddMenu
          openAddMenu={openAddMenu}
          setOpenAddMenu={setOpenAddMenu}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          categoryData={categoryData}
          getLocId={catererId}
          menuItems={menuItems}
          isDupe={isDupe}
        />
      )}
      {openImportMenu && (
        <ImportMenu
          openImportMenu={openImportMenu}
          setOpenImportMenu={setOpenImportMenu}
          catererData={catererData}
          catererId={catererId}
        />
      )}
      {/* dialog box before deleting menu */}
      {openDialog && (
        <DialogBox
          setOpenDialog={setOpenDialog}
          handleDelete={handleDelete}
          openDialog={openDialog}
          title="Delete"
          discription="Are you sure you want to delete this item?"
        />
      )}
      <div className={props.userType !== "Admin" ? "contentWrap" : ""}>
        <div className="contentWrapInner">
          <div className="menu-head-title ">
            {menuItems?.listMenu?.catererData?.businessName && (
              <h5>
                Menu for {menuItems?.listMenu?.catererData?.businessName},{" "}
                {menuItems?.listMenu?.catererData?.locationName}
              </h5>
            )}
          </div>

          <div className="location-head">
            <div className="location-head-title menu-search-by-item justify-content-between">
              <div className="d-flex align-items-center"></div>
              <div className="menu-head-btns d-flex align-items-center">
                {categorySortData?.listCategoryOrder?.data.length > 1 &&
                props.userType !== "Admin" ? (
                  <Link
                    to={`/sort-category/${catererId}`}
                    state={{
                      name: menuItems?.listMenu?.catererData?.businessName,
                      location: menuItems?.listMenu?.catererData?.locationName,
                    }}>
                    <Button className="me-3" color="primary" variant="contained">
                      Sort Category
                    </Button>
                  </Link>
                ) : null}
                <Button color="primary" variant="contained" onClick={() => setOpenAddMenu(true)}>
                  <img src="../assets/images/icon-add-menu.svg" className="img-fluid" alt="Icon" />{" "}
                  Add an Item
                </Button>
                <Button color="primary" variant="contained" onClick={() => setOpenImportMenu(true)}>
                  <img src="../assets/images/icon-cart.svg" className="img-fluid" alt="Icon" />{" "}
                  Import Menu
                </Button>
              </div>
            </div>
          </div>
          {
            <TableContainer>
              <Table className="commonTabel" sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell align="left">Item Name</TableCell>
                    <TableCell align="center">Category</TableCell>
                    <TableCell align="center">Dietary</TableCell>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center">Price</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <p> Loading...</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : menuItems && menuItems?.listMenu?.data?.length > 0 ? (
                    menuItems &&
                    menuItems?.listMenu?.data?.map((item) => (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/menu/" + `${item?._id}`)}>
                          <img
                            src={
                              item?.itemImage === "" || item?.itemImage === null
                                ? "../assets/images/uploadFood.jpg"
                                : item?.itemImage
                            }
                            className="img-fluid menu-list-img"
                            alt={item?.itemName}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/menu/" + `${item?._id}`)}>
                          {item?.itemName}
                        </TableCell>
                        <TableCell align="center">{item?.categoryDtls?.categoryTitle}</TableCell>
                        <TableCell align="center">{item?.dietory}</TableCell>
                        <TableCell align="center">{item?.itemType}</TableCell>
                        <TableCell align="center">
                          {formatter.format(item?.price)} / {item?.minQuantity + " min"}
                        </TableCell>
                        <TableCell align="center">
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              setSelectedItem(item);
                              handleClick(e);
                            }}
                            src="../assets/images/more-horizontal.svg"
                            alt="img"
                          />
                        </TableCell>
                      </TableRow>
                      // </Link>
                    ))
                  ) : search ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <p>No data Found</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <p>No list added Yet!</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </div>
        <div className="table-pagenation">
          <div className="rowCount">
            <Select onChange={handlePageInputChange} value={rowsPerPage}>
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(menuItems?.listMenu?.count / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="menu-list-more">
          {/* navigate to view menu */}
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("/menu/" + `${selectedItem?._id}`);
            }}>
            View
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenAddMenu(true);
              handleClose();
            }}>
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenDialog(true);
              handleClose();
            }}>
            Delete
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenAddMenu(true);
              setIsDupe(true);
              handleClose();
            }}>
            Duplicate
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}
