import React, { useState,useEffect,useRef } from "react";
import { Table, Select, MenuItem, Button, InputAdornment } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useNavigate, useParams, useLocation,Link } from "react-router-dom";
import moment from "moment";
import { userDeatils, toggleSnackbar } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import TableRow from "@mui/material/TableRow";
import { LIST_ALL_ORDERS } from "../../Graphql/queries";
import { UPDATE_SETTLE_AMOUNT } from "../../Graphql/mutations";
import SideMenu from "../../components/organisms/sidebar";
import LoggedHeader from "../Common/loggedHeader";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import Menu from "@mui/material/Menu";
import Switch from "@mui/material/Switch";
import DialogBox from "../Common/dialogBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function PaymentAdminDetail(props) {
  const fromDatePickerRef = useRef(null);
  const toDatePickerRef = useRef(null);
  const navigate = useNavigate()
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const locations = useLocation();
  const datas = locations.state;
  const { id } = useParams();
  const user = useReactiveVar(userDeatils);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState(null);
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [caterId, setCaterId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [paidStatus, setPaidStatus] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [fromDateError, setFromDateError] = useState(false);
  const [toDateError, setToDateError] = useState(false);

  const userType =
    user?.data?.login?.userType ||
    user?.data?.currentUserDetails?.userType ||
    user?.data?.createUserIdentity?.userType ||
    user?.data?.createCaterer?.userType;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }); // currency formater
  const orderVariable = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    orderStatus: ["Completed"],
    catererId: datas?.catererId,
    from:
      fromDate && toDate&&
      new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000),
    to:
    fromDate&& toDate && new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000),
    timeZone: timeZone
    //customerId: userId ,
    // search:searchValue
  };

  const { data, loading } = useQuery(LIST_ALL_ORDERS, {
    fetchPolicy: "network-only",
    variables: orderVariable,
    onCompleted: (res) => {
      res?.listOrders?.data?.map((item, index) => {
        if (id === item?._id) {
          setTitle(item?.catererDetails?.businessName);
          setLocation(item?.locationName);
          setCaterId(item?.catererId);
        }
      });
    },
  });
  // handling pagination
  const handleChangePage = (e, pages) => {
    setPage(pages);
  };

  // change number of lisiting in a page
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  const [updateSettleAmount] = useMutation(
    UPDATE_SETTLE_AMOUNT,
    {
      refetchQueries: ["ListOrders"]
    }
  );
  const handlePaidStatus = () => {
    let request = {
      orderId: selectedItem?._id,
      settlementDate: moment(),
      isPaidToCaterer: true,
    };
    try {
      updateSettleAmount({ variables: { data: request } })
        .then((res) => {
          setPaidStatus(res?.data?.updateSettleAmount?.isPaidToCaterer);
          toggleSnackbar({
            status: true,
            message: "Paid successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const onChangeRawFrom = (e) => {
    // let m = moment(e.target.value);
    // if (!m.isValid()) {
    //   setFromDateError(true)
    // } else {
    //   setFromDateError(false)
    // }
    const value = e.target.value;
  
    if (!value) {
      setFromDateError(false); // Clear the error when the input is empty
      return;
    }
  
    const m = moment(value);
    if (!m.isValid()) {
      setFromDateError(true);
    } else {
      setFromDateError(false);
    }
  };
  const onChangeRawTo = (e) => {
     //   let m = moment(e.target.value);
  //   if (!m.isValid()) {
  //     setToDateError(true)
  //   } else {
  //     setToDateError(false)
  //   }
    const value = e.target.value;
  
    if (!value) {
      setToDateError(false); // Clear the error when the input is empty
      return;
    }
  
    const m = moment(value);
    if (!m.isValid()) {
      setToDateError(true);
    } else {
      setToDateError(false);
    }
  };

  const handleKeyPress = (e) => {
    const allowedKeys = [8, 37, 39, 191]; // Backspace, left arrow, right arrow, slash
    if (!allowedKeys.includes(e.keyCode) && isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }
  useEffect(() => {
    const handleTabKeyDown = (event, ref) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        ref.current.setOpen(false);
      }
    };

    const fromInputField = fromDatePickerRef.current.input;
    const toInputField = toDatePickerRef.current.input;

    fromInputField.addEventListener('keydown', (event) =>
      handleTabKeyDown(event, fromDatePickerRef)
    );

    toInputField.addEventListener('keydown', (event) =>
      handleTabKeyDown(event, toDatePickerRef)
    );

    return () => {
      fromInputField.removeEventListener('keydown', (event) =>
        handleTabKeyDown(event, fromDatePickerRef)
      );
      toInputField.removeEventListener('keydown', (event) =>
        handleTabKeyDown(event, toDatePickerRef)
      );
    };
  }, []);
  function handleKeyDown(e) {
    const value = e.target.value;
    const keyCode = e.keyCode || e.which;
    if (keyCode === 9) { // Tab key
      e.preventDefault();

      const targetName = e.target.name;
      const nextFieldName = targetName === 'from' ? 'to' : 'from';
      const nextField = document.querySelector(`input[name=${nextFieldName}]`);
      nextField.focus();
    }
    if (e.key === 'Backspace' || e.key === 'Delete') {
      return;
    }
    if (!/\d/.test(e.key)) {
      e.preventDefault();
      return;
    }
  
    if (value.length === 2) {
      if (value.charAt(2) !== '/') {
        e.preventDefault();
        e.target.value = `${value}/${e.key}`;
      }
    } else if (value.length === 5) {
      if (value.charAt(5) !== '/') {
        e.preventDefault();
        e.target.value = `${value.slice(0, 2)}${value.slice(2)}/${e.key}`;
      }
    }else if (value.length >= 10) {
      e.preventDefault();
    }
  }
  let orderNo = selectedItem?.orderNo;
  return (
    <div>
      {openDialog && (
        <DialogBox
          setOpenDialog={setOpenDialog}
          handleDelete={handlePaidStatus}
          openDialog={openDialog}
          title="Update Payment Status"
          discription={"Are you sure you want to mark the order #"+orderNo +" as Paid? Please double check payment before settling"
           
          }
        />
      )}
      {/* header for changing location and getting the id */}
      <LoggedHeader
        title={
          (datas?.title || datas?.location) &&
          datas?.title + ", " + datas?.location
        }
      />
      {/* keeping the side bar selected */}
      <SideMenu
        selected={props?.admin ? "Caterers" : "Payments"}
        user={
          userType === "Customer"
            ? "customer"
            : userType === "Caterer"
              ? "caterer"
              : "admin"
        }
      />
      {/* open drawer for adding and editing menu item */}

      <div className={props.userType !== "Admin" ? "contentWrap" : ""}>
        <div className="contentWrapInner">
          
          <a onClick={() => window.history.back()} className="back-link-order">
            <img
              src="../assets/images/back-org.svg"
              className="img-fluid"
              alt="Back"
            />
            Back to Payments{" "}
          </a>
          <div className="location-head">
            <div className="location-head-title justify-content-between align-items-center ">
              {/* <div className="menu-head-title "> */}
              <h5>Total Net Amount: {data?.listOrders?.totalAmt&&formatter.format(data?.listOrders?.totalAmt)}</h5>
              <div className="d-flex align-items-center">
                <div className="lfp-date admin-lfp-date">
                  {fromDateError && <span className="admin-error-msg error-msg">Invalid date format</span>}
                  <DatePicker
                   ref={fromDatePickerRef}
                    selected={fromDate}
                    onChange={(date) => {setFromDate(date);!toDate&& toggleSnackbar({
                      status: true,
                      message: "Please select To date",
                      variant: "error",
                    });}}
                    placeholderText={'Select a from date'}
                    maxDate={toDate}
                    dateFormat="MM/dd/yyyy"
                    name="from"
                    onChangeRaw={onChangeRawFrom}
                    onKeyDown={handleKeyDown}
                    startAdornment={
                      <InputAdornment position="end">
                        <img
                          src="../assets/images/head.svg"
                          className="img-fluid"
                          alt="Head Count"
                        />
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="lfp-date admin-lfp-date ms-3">
                  {toDateError && <span className="admin-error-msg error-msg">Invalid date format</span>}
                  <DatePicker
                   ref={toDatePickerRef}
                    selected={toDate}
                    onChange={(date) => {setToDate(date);   !fromDate&& toggleSnackbar({
                      status: true,
                      message: "Please select From date",
                      variant: "error",
                    });}}
                    placeholderText={'Select a to date'}
                    // showTimeSelect
                    minDate={fromDate}
                    
                    // ref={props?.datepickerRef}
                    dateFormat="MM/dd/yyyy"
                    name="to"
                    onKeyDown={handleKeyDown}
                    onChangeRaw={onChangeRawTo}
                    endAdornment={
                      <InputAdornment position="start">
                        <img
                          src="../assets/images/head.svg"
                          className="img-fluid"
                          alt="Head Count"
                        />
                      </InputAdornment>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="location-head">
            <div className="location-head-title menu-search-by-item justify-content-between">
             
             </div>
             </div> */}

          {
            <TableContainer>
              <Table
                className="commonTabel"
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Order</TableCell>
                    <TableCell align="left">Date & Time</TableCell>
                    <TableCell align="left">Customer Name</TableCell>
                    <TableCell align="left">Order Total</TableCell>
                    <TableCell align="center">Commission</TableCell>
                    <TableCell align="center">CaterCash</TableCell>
                    <TableCell align="center">Processing Fee</TableCell>
                    <TableCell align="center">Service Fee</TableCell>
                    <TableCell align="center">Net Amount</TableCell>
                    <TableCell align="center">Paid</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p> Loading...</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : data?.listOrders?.data &&
                    data?.listOrders?.data?.length > 0 ? (
                    data?.listOrders?.data &&
                    data?.listOrders?.data?.map((item) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {`#${item?.orderNo}`}
                        </TableCell>
                        <TableCell align="left">
                          {`${moment(item?.deliveryDate).format("ddd, MMM DD YYYY")}, ${item?.deliveryTime}`}<br/>
                        { item?.settlementDate&&item?.isPaidToCaterer===true&& <div className="settled-on">Settled on: {moment(item?.settlementDate).format("llll")}</div>}
                        </TableCell>
                        <TableCell style={{cursor:"pointer"}} align="left" onClick={()=>{navigate(`/customers/${item?.customerId}`)}}>
                        {`${item?.customerDetails?.firstName} ${item?.customerDetails?.lastName}`}
                        </TableCell>
                        <TableCell align="center">
                          {formatter.format(item?.grandTotal)}
                        </TableCell>
                        <TableCell align="center">
                          {formatter.format(item?.commisionAmount)}
                        </TableCell>
                        <TableCell align="center">
                          {formatter.format(item?.caterCashTotal)}
                        </TableCell>
                        <TableCell align="center">
                          {formatter.format(item?.creditCardFee)}
                        </TableCell>
                        <TableCell align="center">
                          {formatter.format(item?.convenienceFee)}
                        </TableCell>
                        <TableCell align="center">
                          {formatter.format(item?.netAmount)}
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            {...label}
                            disabled={item?.isPaidToCaterer === true}
                            checked={item?.isPaidToCaterer}
                            onChange={(e) => {
                              setOpenDialog(true);
                              setSelectedItem(item);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      // </Link>
                    ))
                  ) : search ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p>No data Found</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p>No list added Yet!</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </div>
        <div className="table-pagenation">
          <div className="rowCount">
            <Select onChange={handlePageInputChange} value={rowsPerPage}>
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(data?.listOrders?.count / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
}
