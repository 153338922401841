import React, { useEffect, useState } from "react";
import moment from "moment";
import { Avatar, Button, Modal, CircularProgress } from "@mui/material";
import { useLazyQuery } from "@apollo/client";

import { PRINT_INVOICE } from "../../../../Graphql/queries";
import { formatter } from "../../../../Modules/Common/commonUtils";
import { IServerOrderResponse } from "typings/order.api";
import { Tableware } from "@components/organisms/tableware";
import { OrderInvoiceLoading } from "./order-invoice-loading";
import { useGeolocation } from "@utils/hooks/use-location";

interface IOrderInvoiceProps {
  order: IServerOrderResponse;
  orderId: string;
  invoiceUrl: string;
  open: boolean;
  loading: boolean;
  dataLoading: boolean;
  setOpen(v: boolean): void;
}

export function OrderInvoice(props: IOrderInvoiceProps) {
  const locs = props?.order?.street?.split(", ");

  const firstLine = locs?.shift();
  // remove and return the first element
  const secondLine = locs?.join(", ");

  let prevValue = null;

  const [printOrderInvoices, { data: printInvoice }] = useLazyQuery(PRINT_INVOICE, {
    variables: {
      orderId: props?.order?._id,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    onCompleted: (res) => {
      window.open(res.printOrderInvoice.pdfUrl, "_blank");
    },
  });

  const handleClose = () => props?.setOpen(false);

  function downloadTxtFile() {
    fetch(props?.invoiceUrl).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `Invoice_#${props?.order?.orderNo}.pdf`;
        alink.click();
      });
    });
  }

  const [distance, setDistance] = useState("");
  const { getDistance } = useGeolocation();

  useEffect(() => {
    if (!props?.order?.catererDetails?.locationName || !props?.order?.locationName) {
      setDistance("");
      return;
    }

    fetchDistance(
      `${props?.order?.catererDetails?.locationName} ${
        props?.order?.catererDetails?.catererZipCode || ""
      }`,
      `${props?.order?.locationName} ${props?.order?.zip}`,
    );
  }, [props?.order?.catererDetails?.locationName, props?.order?.locationName]);

  async function fetchDistance(catererLocation, address) {
    const value = await getDistance(catererLocation, address);

    setDistance(value);
  }

  return (
    <Modal
      open={props?.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="caterer-menu-modal order-invoice">
      <div className="caterer-menu-details">
        <div id="modal-modal-description">
          {props?.dataLoading ? (
            <OrderInvoiceLoading />
          ) : (
            <>
              <div className="caterer-menu-header">
                <div>
                  <h5 className="h5">Order: #{props?.order?.orderNo}</h5>
                  <small>
                    Delivery Date:&nbsp;
                    {moment(props?.order?.deliveryDate).format("ddd, MMM DD YYYY,")}
                    {props?.order?.deliveryTime}
                  </small>
                  <small style={{ display: distance ? "flex" : "hidden" }}>
                    Distance:&nbsp;
                    {distance} miles
                  </small>
                </div>
                <div>
                  <span style={{ cursor: "pointer" }} onClick={() => handleClose()}>
                    <img
                      src="../assets/images/modal-close.svg"
                      className="img-fluid caterer-menu-close d-block"
                      alt="close icon"
                    />
                  </span>
                </div>
              </div>
              <div className="order-view caterer-menu-content">
                <div className="order-view-address-wrap">
                  <div className="row">
                    <div className="col-md-6">
                      <h6> Customer </h6>
                      <div className="order-view-address order-view-address-mob">
                        <Avatar
                          src={
                            props?.order?.customerDetails?.imageUrl
                              ? props?.order?.customerDetails?.imageUrl
                              : "/static/images/avatar/1.jpg"
                          }>
                          {props?.order?.contactPerson.charAt(0).toUpperCase()}
                        </Avatar>
                        <div>
                          <h6>
                            {props?.order?.customerDetails?.firstName +
                              " " +
                              props?.order?.customerDetails?.lastName}
                          </h6>
                          <p>{props?.order?.contactPerson}</p>
                          {(props?.order?.suit || props?.order?.location) && (
                            <p>
                              {props?.order?.location}
                              {props?.order?.location && props?.order?.suit && ", "}
                              {props?.order?.suit ? props?.order?.suit.replace(/,/g, "") : ""}
                            </p>
                          )}

                          <p>{firstLine}</p>
                          <p>{secondLine + ", " + props?.order?.zip}</p>
                          <p>
                            {props?.order?.contactNo
                              ? props?.order?.contactNo
                              : props?.order?.customerDetails?.phoneNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h6> Caterer </h6>
                      <div className="order-view-address">
                        <Avatar
                          src={
                            props?.order?.catererDetails?.imageUrl
                              ? props?.order?.catererDetails?.imageUrl
                              : "/static/images/avatar/1.jpg"
                          }>
                          {props?.order?.catererDetails?.businessName.charAt(0).toUpperCase()}
                        </Avatar>
                        <div>
                          <h6>{props?.order?.catererDetails?.businessName}</h6>
                          <p>{props?.order?.catererDetails?.locationName}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="od-common-box">
                  <p style={{ fontSize: "14px", textTransform: "inherit" }}>
                    {props?.order?.orderStatus === "Completed" ? (
                      <>
                        Paid with {props?.order?.cardDetails?.cardType}&nbsp;card&nbsp;
                        <span style={{ fontWeight: 700 }}>
                          XXXX XXXX XXXX {props?.order?.cardDetails?.cardLastDigits?.slice(-5)}
                        </span>{" "}
                        on&nbsp;
                        <span style={{ fontWeight: 700 }}>
                          {moment(props?.order?.dateCompleted).format("MM/DD/YYYY")}
                        </span>
                      </>
                    ) : props?.order?.orderStatus !== "Cancelled" ? (
                      <>
                        {" "}
                        Your {props?.order?.cardDetails?.cardType}&nbsp;card ending in&nbsp;
                        <span style={{ fontWeight: 700 }}>
                          {props?.order?.cardDetails?.cardLastDigits?.slice(-5)}
                        </span>{" "}
                        will not be charged until your order has been completed
                      </>
                    ) : (
                      <>
                        This Order was cancelled on&nbsp;
                        <span style={{ fontWeight: 700 }}>
                          {moment(props?.order?.dateCancelled).format("ddd, MMM DD YYYY")} at{" "}
                          {moment(props?.order?.dateCancelled).format("LT")}
                        </span>
                      </>
                    )}
                  </p>
                </div>
                {props?.order?.isRefunded && props?.order?.refundText && (
                  <div className="od-common-box">
                    <p style={{ fontSize: "14px", textTransform: "inherit" }}>
                      {`Reason for Refund : ${props?.order?.refundText}`}
                    </p>
                  </div>
                )}
                <div className="review-order-item ">
                  {props?.order?.cartDetails?.map((order, index) => {
                    return (
                      <div className="roi-single">
                        <div className="d-flex justify-content-between">
                          <div className="col-md-9 roi-details">
                            <h6
                              className={
                                props?.order?.cartDetails.length > 1 && index > 0
                                  ? "mb-0 order-item-highlight"
                                  : "mb-0"
                              }>
                              {order?.itemName}
                            </h6>
                            {order?.addOns?.length > 0 && (
                              <div className="roi-subaddon-wrap">
                                {order?.addOns?.map((item, index) => {
                                  if (item.addOnTypeName !== prevValue) {
                                    prevValue = item.addOnTypeName;
                                    return (
                                      <div key={index}>
                                        <p className="roi-subaddon">
                                          {`${item?.addOnTypeName ?? "add on type removed"} : `}
                                          {`${item?.addOnName}`}
                                          {item?.subAddOns.length > 0 && <b> | </b>}
                                          {item?.subAddOns?.map((item) => (
                                            <span>
                                              {`${item?.subAddOnName}`}
                                              <b> / </b>
                                            </span>
                                          ))}
                                        </p>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div key={index}>
                                        <p className="roi-subaddon">
                                          {" "}
                                          {`${item?.addOnName}`}
                                          {item?.subAddOns.length > 0 && <b> | </b>}
                                          {item?.subAddOns?.map((item) => (
                                            <span>
                                              {`${item?.subAddOnName}`}
                                              <b> / </b>
                                            </span>
                                          ))}
                                        </p>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 roi-count-price text-end">
                            <span style={{ color: "#FD4E3B", flexShrink: 0 }}>
                              {order?.quantity} X&nbsp;
                            </span>
                            {formatter.format(order?.totalPricePerUnit)}
                          </div>
                        </div>
                        {order?.cartInstruction && (
                          <div className="roi-sp-instr">
                            {order?.cartType !== "Custom" && <h6>Special Instructions</h6>}
                            <p>{order?.cartInstruction}</p>
                          </div>
                        )}
                        <div></div>
                      </div>
                    );
                  })}
                </div>
                <div className="review-order-price">
                  <div className="other-charges">
                    {props?.order?.discountAmt !== 0 && (
                      <div className="other-charges-item">
                        <label>Discount</label>
                        <b>
                          {formatter.format(
                            props?.order?.discountAmt ? props?.order?.discountAmt : 0,
                          )}
                        </b>
                      </div>
                    )}
                    <div className="other-charges-item">
                      <label>Subtotal</label>
                      {props?.order?.totalPrice && (
                        <b>{formatter.format(props?.order?.totalPrice)}</b>
                      )}
                    </div>
                    <Tableware
                      cost={props?.order?.tablewareAmount * props?.order?.headCount}
                      isCharged={props?.order?.tablewareCharged || false}
                      isRemoved={props?.order?.tablewareDeclined || false}
                    />
                    {props?.order?.deliveryMode != "Pickup Only" && (
                      <div className="other-charges-item">
                        <label>Delivery Fee</label>
                        {props?.order?.deliveryFee && (
                          <b>{formatter.format(props?.order?.deliveryFee)}</b>
                        )}
                      </div>
                    )}
                    <div className="other-charges-item">
                      <label>Service Fee</label>
                      {props?.order?.convenienceFee && (
                        <b>{formatter.format(props?.order?.convenienceFee)}</b>
                      )}
                    </div>
                    <div className="other-charges-item">
                      <label>{`${props?.order?.salesTaxPercentage}% Sales Tax`}</label>
                      {props?.order?.salesTaxAmount ?
                        <b>{formatter.format(props?.order?.salesTaxAmount)}</b>
                        : <b>$0</b>
                      }
                    </div>
                    <div className="other-charges-item">
                      <label>Tip for Driver</label>
                      <b>
                        {formatter.format(props?.order?.driverTip ? props?.order?.driverTip : 0)}
                      </b>
                    </div>
                    {props?.order?.isRefunded && (
                      <div className="other-charges-item">
                        <label>Refund</label>
                        {props?.order?.refundAmount && (
                          <b>-{formatter.format(props?.order?.refundAmount)}</b>
                        )}
                      </div>
                    )}
                    <div className="other-charges-item">
                      <label>Headcount</label>
                        {props?.order?.salesTaxAmount ?
                          <b>{props?.order?.headCount}</b>
                          : <b>0</b>}
                      </div>
                    <div
                      className="cart-total common-cart-viewbox"
                      style={{ borderTop: "0.6px solid #D8D8D8", borderBottom: "0" }}>
                      <label>Total</label>
                      {props?.order?.grandTotal && (
                        <b>{formatter.format(props?.order?.grandTotal)}</b>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="caterer-menu-footer">
                <Button
                  className="cancel-btn"
                  disabled={props?.loading}
                  onClick={() => {
                    downloadTxtFile();
                  }}
                  variant="outlined">
                  {props?.loading ? <CircularProgress color="inherit" size={20} /> : " Save"}
                </Button>
                <Button
                  className="print-btn"
                  variant="contained"
                  onClick={() => {
                    printOrderInvoices();
                  }}>
                  Print
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
